@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--font-sans: 'Helvetica Neue', 'Helvetica Neue Fallback';
		/* --font-mono: here if you got it... */

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: 345 82.7% 40.8%;

		--background: 0 0% 100%;
		--foreground: 203 20% 18%;

		--muted: 210 11% 96%;
		--muted-foreground: 216 5% 40%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--border: 214.3 31.8% 91.4%;
		--input: 0 0% 0%;
		--input-invalid: 0 84.2% 60.2%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 286 100% 78%;

		--secondary: 210 20% 83%;
		--secondary-foreground: 234 100% 50%;

		--accent: 210 40% 90%;
		--accent-foreground: 286 100% 78%;

		--destructive: 0 70% 50%;
		--destructive-foreground: 357 85% 64%;

		--ring: 0 0% 0%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: -4 84% 60%;

		--muted: 217.2 32.6% 12%;
		--muted-foreground: 215 20.2% 65.1%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 0 0% 100%;
		--input-invalid: 0 62.8% 30.6%;

		--primary: 210 40% 98%;
		--primary-foreground: 286 100% 78%;

		--secondary: 217.2 20% 24%;
		--secondary-foreground: 210 97% 54%;

		--accent: 210 40% 90%;
		--accent-foreground: 286 100% 78%;

		--destructive: 0 60% 40%;
		--destructive-foreground: 357 85% 64%;

		--ring: 0 0% 100%;
	}
}

@layer components {
	.container-narrow {
		@apply mx-auto max-w-[1046px] px-8;
	}

	.skip-link {
		clip: rect(1px, 1px, 1px, 1px);
		display: block;
		height: 1px;
		overflow: hidden;
		padding: 20px;
		position: absolute;
		text-decoration: underline;
		top: 1rem;
		left: 1rem;
		width: 1px;
		z-index: 999;
	}

	.skip-link:focus {
		clip: auto;
		height: auto;
		overflow: visible;
		width: auto;

		@apply rounded-lg ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 motion-reduce:transition-none;
	}

	.navigation-view-transition {
		view-transition-name: navigation;
	}

	.footer-view-transition {
		view-transition-name: footer;
	}

	.blog-card-image-transition {
		view-transition-name: blog-card-image;
	}

	.blog-card-title-transition {
		view-transition-name: blog-card-title;
	}

	.blog-card-date-transition {
		view-transition-name: blog-card-date;
	}
}
